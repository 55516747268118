import React from 'react';

const GameChangerMessage = () => {
    let wrapData = [
        {
            title: "Grocery",
            Image: require("../Assets/image-wrapi-1.webp")
        },
        {
            title: "Fashion",
            Image: require("../Assets/image-wrapi-2.webp")
        },
        {
            title: "Electronics",
            Image: require("../Assets/image-wrapi-3.webp")
        },
        {
            title: "Jewellery",
            Image: require("../Assets/image-wrapi-4.webp")
        },
        {
            title: "Cosmetics",
            Image: require("../Assets/image-wrapi-5.webp")
        },
        {
            title: "Food",
            Image: require("../Assets/image-wrapi-6.webp")
        },
        {
            title: "Gifts & Novelties",
            Image: require("../Assets/image-wrapi-7.webp")
        },
        {
            title: "Cake Shoppe",
            Image: require("../Assets/image-wrapi-8.webp")
        },
        {
            title: "Restaurants",
            Image: require("../Assets/image-wrapi-9.webp")
        },
        {
            title: "Furniture",
            Image: require("../Assets/image-wrapi-10.webp")
        }
    ]
    return (
        <section className="game-changer-message">
            <div className='container'>
                <div className='text-center'>
                    <h2 className='dash-head5'>We Can Be A Game Changer For Your Business</h2>
                    <p className='font-p c-1' style={{ color: "#FFF" }}>Be it engaging users, streamlining operations or expanding your footprints Shopdiy would work along to help you gain the tech excellence<br /> using the latest technologies and industry best practices</p>
                </div>
                <div className="wrap-container">
                    {wrapData.map((item, index) => (
                        <div key={index} className="wrap-item img">
                            <div style={{height:"296px"}}>
                            <img src={item.Image} alt={item.title} style={{height:"100%"}}/>
                            </div>
                            <div className='title-btn'>{item.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export { GameChangerMessage };