import React from "react";

const AccessiblePurchaseComponent = () => {
    return (
        <div>
            <section style={{ background: "#00A295", paddingTop: "55px", paddingBottom: "55px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="image-Acess">
                                <img src={require("../Assets/image 711.webp")} alt="image 711.png" style={{maxHeight:"281px"}}/>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div style={{paddingBottom:"24px"}}>
                                <button className="button btn-black">Early access offer</button>
                            </div>
                            <div>
                                <h4 className="font-access">Make Purchases More Accessible</h4>
                            </div>
                            <div style={{ marginTop: "25px", marginBottom: "30px" }}>
                                <p className="f-1">
                                    Want to have results driving against all odds; Look no further? Our powerful control
                                    panel customised mobile apps would help you sell your products seamlessly across
                                    various geographies. Interact and reach your users easily and seamlessly. Gain Your
                                    Brand visibility sooner than expected
                                </p>
                            </div>
                            <div>
                                <button className="btn-dld">Download Our App</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ paddingTop: "42px", paddingBottom: "42px", background: "#FFF" }}>
                <div>
                    <h4 className="shody-online">Shop Diy - Where Your Online Store Comes To Life</h4>
                </div>
            </section>
            <section className="sticky" style={{ paddingTop: "35px", paddingBottom: "36px" }}>
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        <h4 className="dash-ser t-1">Our Services</h4>
                    </div>
                    <div className="col-md-8 flex-m">
                        <div className="btns-acess">
                            <div>
                                <button className="btn-white-flex img">Website Design</button>
                            </div>
                            <div>
                                <button className="btn-white-flex img">E commerce Platform</button>
                            </div>
                            <div>
                                <button className="btn-white-flex img">Customization</button>
                            </div>
                            <div>
                                <button className="btn-white-flex img">Mobile Apps</button>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
            </section>
        </div>
    )
}

export { AccessiblePurchaseComponent };