import React, { useState } from 'react';
import './Modal.css'; // Import your CSS file for modal styles
import { NavLink } from 'react-router-dom';
import { MdOutlineStarPurple500 } from "react-icons/md";
const NavBarModal = ({ isOpen, onClose,settogole }) => {
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
      <nav className='container' onClick={()=>settogole(!isOpen)}>
            <div className='d-flex flex-column'style={{paddingBottom:"24px"}}>
             <div>
                <NavLink exact to="/" activeClassName="active">Home Page</NavLink>
              </div>
             <div>
                <NavLink to="/about" activeClassName="active">About</NavLink>
              </div>
             <div>
                <NavLink to="/features" activeClassName="active">Features</NavLink>
              </div>
             <div>
                <NavLink to="/blog" activeClassName="active">Blog</NavLink>
              </div>
             <div>
                <NavLink to="/contact" activeClassName="active">Contact</NavLink>
              </div>
             <div>
                <NavLink to="/purchase" activeClassName="active">Purchase Now</NavLink>
              </div>
            </div>
            <button className='btn-block btn-feedback'><MdOutlineStarPurple500 size={20}/> Feedback</button>
          </nav>
      </div>
    </div>
  );
};
export {NavBarModal}