import React, { useState } from 'react';

const Platform = () => {
    let Platfrom = [
        {
            Headers: "Why Choose Us",
            sub_header: "One Platform, Many Channels",
            className: "col-md-6 platform-1",
            list: ["Customized Website", "Payment Gateway", "delivery integration", "push  notifications", "multi stuff accounts", "Mobile Apps"]
        },
        {
            Headers: "",
            sub_header: "",
            className: "col-md-6 platform-2",
            list: [],
            Image: require("../Assets/image-Platform-1.webp")
        },
    ]
    let Platfrom3 = [
        {
            Headers: "Why Choose Us",
            sub_header: "",
            className: "col-md-6 platform-1",
            list: ["Shopdiy would offer dedicated training & support to ensure your E-commerce platform remains up to date, secure, and optimized for performance",
                "Our highly secure cloud architecture enables you to scale up or scale down based on demand",
                "Deliver products to your buyers by partnering with logistic partners",
                "With us you’ll be able to focus more on your business without compromising on your efficiency",
            ]
        },
        {
            Headers: "",
            sub_header: "",
            className: "col-md-6 platform-2",
            list: [],
            Image: require("../Assets/image-Platform-3.webp")
        },
    ]
    let Platform2 = [
        {
            Headers: "",
            sub_header: "",
            className: "col-md-6 platform-2",
            list: [],
            Image: require("../Assets/platform-2.webp")
        },
        {
            Headers: "Pay For What You Need",
            sub_header: "",
            className: "col-md-6 platform-1",
            list: ["Don’t allow limited budgets hold you back from building your business.",
                "Shopdiy pricing is flexible which ensures accessibility for business of all sizes and budgets",
                "Dynamic Pricing – One size fits all does not work for all kinds of e commerce. Our personalized and dynamic pricing are designed to fit your growth goals",
                "To keep your buyers engaged you can upgrade app by leveraging advanced technologies and pay for just wat you need"]
        },
    ]
    let Platform4 = [
        {
            Headers: "",
            sub_header: "",
            className: "col-md-6 platform-2",
            list: [],
            Image: require("../Assets/image-removebg-preview.webp")
        },
        {
            Headers: "Transparency In Transactions",
            sub_header: "",
            className: "col-md-6 platform-1",
            list: ["Payment gateway, shipping carriers, and SMS gateways can help add significant value to the app",
                "Integrate with varied payment gateways i.e., Credit, debit cards, net banking, UPI, & so on",
                "Build trust with your customers with our safe payment gateways that , would maintain transparency in ecommerce transactions",
                "Customers can monitor their purchases via the app"]
        },
    ]
    let Platfrom5 = [
        {
            Headers: "You Are The Owner",
            sub_header: "",
            className: "col-md-6 platform-1",
            list: ["Payment gateway, shipping carriers, and SMS gateways can help add significant value to the app",
                "With Shopdiy, you can choose your own theme design for your E-commerce store that aligns with your brand identity",
                "Intuitive designs that enhance user experience, drive engagement, and boost conversions", "Mobile apps can enhance your online sales and offer a seamless shopping experience to the users"]
        },
        {
            Headers: "",
            sub_header: "",
            className: "col-md-6 platform-2",
            list: [],
            Image: require("../Assets/image-removebg-preview -5.webp")
        },
    ]
    return (
        <div>
            <section className='bggray'>
                <div className='container'>
                    <div className='row'>
                        {Platfrom.map((item, index) => (
                            <div className={`${item.className}`}>
                                <div>
                                    {item.Headers && <h4 className='dash-ser'>{item.Headers}</h4>}
                                    {item.sub_header && <p className="dash-head1">{item.sub_header}</p>}
                                    {item.list && <ul className='ul-list'>
                                        {item.list && item.list.map((listItem, listIndex) => (
                                            <div className='d-flex align-items-center gap-4'>
                                                <div>
                                                    <img src={require("../Assets/Vector-left.png")} alt='Vector-left.png' />
                                                </div>
                                                <div>
                                                    <li key={listIndex} className='list-fonts'>{listItem}</li>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>}
                                </div>
                                <div>
                                    {item.Image && <img className='img' src={item.Image} alt="Platform" style={{maxWidth:"527px", width: "100%", height: "auto" }} />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section style={{ background: "#00A295" }}>
                <div className='container'>
                    <div className='row'>
                        {Platform2.map((item, index) => (
                            <div className={`${item.className}`} style={{ background: "none" }}>
                                <div>
                                    {item.Headers && <h4 className='dash-ser'>{item.Headers}</h4>}
                                    {item.sub_header && <p className="dash-head1">{item.sub_header}</p>}
                                    {item.list && <ul className='ul-list'>
                                        {item.list && item.list.map((listItem, listIndex) => (
                                            <div className='d-flex align-items-center gap-4'>
                                                <div>
                                                    <img src={require("../Assets/Vector-left.png")} alt='Vector-left.png' />
                                                </div>
                                                <div>
                                                    <li key={listIndex} className='list-fonts'>{listItem}</li>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>}
                                    <div>
                                        {item.Image && <img className='img' src={item.Image} alt="Platform" style={{maxWidth:"527px", width: "100%", height: "auto" }} />}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className='bggray'>
                <div className='container'>
                    <div className='row'>
                        {Platfrom3.map((item, index) => (

                            <div className={`${item.className}`}>
                                <div >
                                    {item.Headers && <h4 className='dash-ser'>{item.Headers}</h4>}
                                    {item.sub_header && <p className="dash-head1">{item.sub_header}</p>}
                                    {item.list && <ul className='ul-list'>
                                        {item.list && item.list.map((listItem, listIndex) => (
                                            <div className='d-flex align-items-center gap-4'>
                                                <div>
                                                    <img src={require("../Assets/Vector-left.png")} alt='Vector-left.png' />
                                                </div>
                                                <div>
                                                    <li key={listIndex} className='list-fonts'>{listItem}</li>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>}
                                    <div>
                                        {item.Image && <img className='img' src={item.Image} alt="Platform" style={{maxWidth:"527px", width: "100%", height: "auto" }} />}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section style={{ background: "#00A295" }}>
                <div className='container'>
                    <div className='row'>
                        {Platform4.map((item, index) => (
                            <div className={`${item.className}`} style={{ background: "none" }}>
                                <div>
                                    {item.Headers && <h4 className='dash-ser'>{item.Headers}</h4>}
                                    {item.sub_header && <p className="dash-head1">{item.sub_header}</p>}
                                    {item.list && <ul className='ul-list'>
                                        {item.list && item.list.map((listItem, listIndex) => (
                                            <div className='d-flex align-items-center gap-4'>
                                                <div>
                                                    <img src={require("../Assets/Vector-left.png")} alt='Vector-left.png' />
                                                </div>
                                                <div>
                                                    <li key={listIndex} className='list-fonts'>{listItem}</li>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>}
                                    <div>
                                        {item.Image && <img className='img' src={item.Image} alt="Platform" style={{maxWidth:"527px", width: "100%", height: "auto" }} />}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className='bggray'>
                <div className='container'>
                    <div className='row'>
                        {Platfrom5.map((item, index) => (
                            <div className={`${item.className}`}>
                                <div>
                                    {item.Headers && <h4 className='dash-ser'>{item.Headers}</h4>}
                                    {item.sub_header && <p className="dash-head1">{item.sub_header}</p>}
                                    {item.list && <ul className='ul-list'>
                                        {item.list && item.list.map((listItem, listIndex) => (
                                            <div className='d-flex align-items-center gap-4'>
                                                <div>
                                                    <img src={require("../Assets/Vector-left.png")} alt='Vector-left.png' />
                                                </div>
                                                <div>
                                                    <li key={listIndex} className='list-fonts'>{listItem}</li>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>}
                                </div>
                                <div>
                                    {item.Image && <img className='img' src={item.Image} alt="Platform" style={{maxWidth:"527px", width: "100%", height: "auto" }} />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className='' style={{ background: "#BAD8D5",paddingTop:"40px" }}>
                <div className='container'>
                <h4 className='dash-ser text-center'>Don’t Just Take Our Word For It!</h4>
                    <div className='row' style={{paddingTop:"42px"}}>
                        <div className='col-md-6'>
                            <div  className="d-flex justify-content-center">
                                <img  src={require("../Assets/image preview6.webp")} alt='image-preview-6' style={{maxWidth:"364px", width: "100%", height: "auto" }}  />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div>
                            <div>
                                <img src={require("../Assets/double.png")} alt='double'/>
                                </div>
                            <div className='v-harvest'>
                                <h4 className='v-1'>Hear What Our Clients Have To Say</h4>
                                <p className='font-p'>ShopDiy has skyrocketed our sales and made our products fly off the shelves. Shopdiy multiple store set up, catalogue & order management, in app notifications, shipment tracking, and payment accounting has helped us expand our footprints in a short span</p>
                                <h4 className='v-1' style={{color:"#DC7B10",fontWeight:"400"}}>Mallikarjun</h4>
                                <h4 style={{fontSize:"18px",fontWeight:"500"}}>Co Founder Of V Harvest</h4>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export { Platform };
