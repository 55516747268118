import React from 'react';
import './App.css';
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Header,Banner,MainBodyTemplate,Footer } from './Components';
import { About } from './About';
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/"  element={<MainBodyTemplate/>} />
          <Route path="/about"  element={<About/>} />
          </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
