import React, { useState, useEffect,useRef } from "react";
import 'animate.css';
import { useInView } from 'react-intersection-observer';
const InventryComponent = () => {
    return (
        <div  style={{ marginTop: "44px", marginBottom: "60px" }}>
            <div class={`container`} id="scrollable-element">
                <div className="flex-item anim-4">
                    <div  className={`dash-cards`}>
                        <h4 className="dash-text-secound">Sort Your Inventory</h4>
                    </div>
                    <div className="dash-cards-2">
                        <div className="dash-img-2">
                            <img src={require("../Assets/image-Photoroom.webp")} alt="Photoroom Product Image" style={{ minWidth: "106px", minHeight: "108" }} />
                        </div>
                        <div>
                            <h4 className="dash-head1">Tailor Product Searches</h4>
                        </div>
                        <div   className={`card-para`}>
                            <p>Direct your users to browse relevant areas of the store with our smart filtering option. The easier it is for users to find things, the faster their purchases will be. This can help boost revenue.</p>
                        </div>
                        <div>
                            <button className="btn-black">Explore More</button>
                        </div>
                    </div>
                </div>
            </div>
            <div  class={`container`} style={{ marginTop: "20px" }}>
                <div className='flex-item'>
                    <div className='dash-cards-2 anim-1 anim-4'>
                        <div className='dash-img-2'>
                            <img src={require("../Assets/Group2.webp")} alt='image-Photoroom' />
                        </div>
                        <div>
                            <h4 className='dash-head1 animate__fadeIn'>Delight Your Customers</h4>
                        </div>
                        <div className='card-para'>
                            <p>Coupons are an effective campaign strategy for promoting the new products to the existing customers as well as for new user acquisition.</p>
                        </div>
                        <div>
                            <button className='btn-black'>Explore More</button>
                        </div>
                    </div>
                    <div className='dash-cards1'>
                        <div className='row'>
                            <div className='col-md-6 ot-1'>
                                <div>
                                    <h4 className='dash-head1'>Give Shoppers A Reason To Return</h4>
                                </div>
                                <div>
                                    <p className='card-para1'>
                                        Shopdiy's user-friendly wishlist option allows you to understand customer preferences, thereby enabling targeted marketing recommendations.
                                    </p>
                                </div>
                                <div>
                                    <button className='btn-black'>Explore More</button>
                                </div>
                            </div>
                            <div className='col-md-6 '>
                                <div className='dash-img-2' style={{ height: "100%" }}>
                                    <img src={require("../Assets/image-Photoroom-2.webp")} alt='image-Photoroom-2' style={{ width: "311px", height: "187px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container" style={{ marginTop: "20px" }}>
                <div className='flex-item'>
                    <div className='dash-cards1 '>
                        <div className='row'>
                            <div className='col-md-6 ot-1'>
                                <div>
                                    <h4 className='dash-head1'>Work With Variants</h4>
                                </div>
                                <div>
                                    <p className='card-para1'>
                                        With Shopdiy you no longer need to create different versions of the same product. We offer the feature wherein you can add variants to products. This feature allows users to choose the specific attributes they prefer increasing the likelihood of purchase
                                    </p>
                                </div>
                                <div>
                                    <button className='btn-black'>Explore More</button>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='dash-img-2' style={{ height: "100%" }}>
                                    <img className="image-fluid" src={require("../Assets/group3.webp")} alt='image-Photoroom-2' style={{ maxHeight: "245px" }} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='dash-cards-2'>
                        <div className='dash-img-2'>
                            <img src={require("../Assets/Group 5.webp")} alt='image-Photoroom' />
                        </div>
                        <div>
                            <h4 className='dash-head1'>Shipment Management! Do What’s Right</h4>
                        </div>
                        <div className='card-para'>
                            <p>Optimize the entire process of getting products from the buyer to seller. Shopdiy streamlined shipment process allows for quicker order processing and dispatch</p>
                        </div>
                        <div>
                            <button className='btn-black'>Explore More</button>
                        </div>

                    </div>

                </div>
            </div>
            <div className="container" style={{ marginTop: "20px" }}>
                <div className='flex-item'>
                    <div className='dash-cards4' style={{ minHeight: "227px" }}>
                        <div className='row'>
                            <div className='col-md-6 ot-1'>
                                <div>
                                    <h4 className='dash-head1'>Selling Is An Art</h4>
                                </div>
                                <div>
                                    <p className='card-para1'>
                                        Display of related products entitles users to make additional purchases thereby enhancing the average order value Presents an opportunity to cross sell and upsell by suggesting higher priced or enhanced quality products
                                    </p>
                                </div>
                                <div>
                                    <button className='btn-black'>Explore More</button>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='dash-img-2' style={{ height: "100%" }}>
                                    <img src={require("../Assets/Group4.webp")} alt='image-Photoroom-2' style={{ maxHeight: "174px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='dash-cards-3'>
                        <div>
                            <h4 className='dash-head2'>And a lot more</h4>
                        </div>
                        <div>
                            <button className='btn-green'>Explore More</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export { InventryComponent }