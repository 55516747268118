import React from 'react';
import { IoMdCheckmarkCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io';
function PayForWhatYouNeed() {
    let data = [
        { Future: "Domain Mapping", basic: "Domain Mapping", standard: "Domain Mapping", advanced: "Domain Mapping", enterprise: "Domain Mapping" },
        { Future: "CMS including Custom SMS", basic: "CMS", standard: "CMS including Custom SMS", advanced: "CMS including Custom SMS", enterprise: "CMS including Custom SMS" },
        { Future: "Multi Store", basic: "Single Store", standard: "CMS including Custom SMS", advanced: "CMS including Custom SMS", enterprise: "CMS including Custom SMS" },
        { Future: "Multi Staff Account", basic: "Single Store", standard: "Multi Store", advanced: "Multi Store", enterprise: "Multi Store" },
        { Future: "Integrated Payment Gateway", basic: "1 Staff Account", standard: "2 Staff Account", advanced: "Multi Staff Account", enterprise: "Multi Staff Account" },
        { Future: "Blog", basic: "3.5% Transaction Charges", standard: "3% Transaction Charges", advanced: "Integrated Payment Gateway", enterprise: "Integrated Payment Gateway" },
        { Future: "Abandonment Cart Information", basic: "Blog", standard: "Blog", advanced: "Blog", enterprise: "Blog" },
        { Future: "Promotional Push Notifications", basic: "", standard: "Abandonment Cart Information", advanced: "Abandonment Cart Information", enterprise: "Abandonment Cart Information" },
        { Future: "Delivery App Integration", basic: "", standard: "Promotional Push Notifications", advanced: "Promotional Push Notifications", enterprise: "Promotional Push Notifications" },
        { Future: "Mobile App including IOS", basic: "", standard: "Delivery App Integration", advanced: "Delivery App Integration", enterprise: "Delivery App Integration" },
        { Future: "Premium Unlimited Tech Support", basic: "", standard: "Mobile App", advanced: "Mobile App including IOS", enterprise: "Mobile App including IOS" },
        { Future: "Customizations", basic: "", standard: "", advanced: "Premium Unlimited Tech Support", enterprise: "Premium Unlimited Tech Support" },
        { Future: "ENQUIRE NOW", basic: "Rs 999/- Monthly", standard: "2999/-Monthly", advanced: "5999/-Monthly", enterprise: "ENQUIRE NOW" }
    ];
    return (
        <section style={{ background: "#00A295", paddingTop: "30px" }}>
            <div className='container'>
                <div className='' style={{ textAlign: "center", color: "#FFF", paddingBottom: "45px" }}>
                    <div>
                        <h4 className='dash-head2'>Pay For What You Need</h4>
                    </div>
                    <div>
                        <p className='font-p-features' style={{ color: "#FFF" }}>Don’t allow limited budgets hold you back from building your business.<br />We have an hybrid model of pricing for users: Flexible & Dynamic Pricing</p>
                    </div>
                </div>
                <div style={{ borderRadius: '44px', border: "14px solid Black", }}>
                    <table className='table' style={{ marginBottom: "0px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "20%",paddingBottom:"28px",paddingTop:"28px",background:"#4A2269",color:"white" }}>Future</th>
                                <th style={{ width: "20%",paddingBottom:"28px",paddingTop:"28px",background:"#BF2076",color:"white" }}>Basic</th>
                                <th style={{ width: "20%",paddingBottom:"28px",paddingTop:"28px",background:"#237373",color:"white" }}>Standard</th>
                                <th style={{ width: "20%",paddingBottom:"28px",paddingTop:"28px",background:"#0673AA",color:"white" }}>Advanced</th>
                                <th style={{ width: "20%",paddingBottom:"28px",paddingTop:"28px",background:"#EA9227",color:"white" }}>Enterprise</th>

                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                               <tr key={index} className={index === data.length - 1 ? 'last-row' : ''}>
                               <td className='text-left card-para'>{item.Future}</td>
                               <td className='text-center card-para'>
                                   {index === data.length - 1 ? item.basic : item.basic ? <img src={require("../Assets/yes.png")} /> : <img src={require("../Assets/wrong.png")} />}
                               </td>
                               <td className='text-center card-para'>
                                   {index === data.length - 1 ? item.standard : item.standard ? <img src={require("../Assets/yes.png")} /> : <img src={require("../Assets/wrong.png")} />}
                               </td>
                               <td className='text-center card-para'>
                                   {index === data.length - 1 ? item.advanced : item.advanced ? <img src={require("../Assets/yes.png")} /> : <img src={require("../Assets/wrong.png")} />}
                               </td>
                               <td className='text-center card-para'>
                                   {index === data.length - 1 ? item.enterprise : item.enterprise ? <img src={require("../Assets/yes.png")} /> : <img src={require("../Assets/wrong.png")} />}
                               </td>
                           </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
}

export { PayForWhatYouNeed };
