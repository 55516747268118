import React from "react";
import 'animate.css';
const Banner=()=>{
    return(
        <section className='container animate__animated animate__backInLeft' style={{paddingBottom:"68px"}}>
        <div className='row'>
          <div className='col-md-7'>
            <div className='banner'>
              <div>
                <div>
                  <h4 className='dash-board-content '>We Engineer Experiences Using Latest Technologies
                    And Industry Best Practices </h4>
                </div>
                <div style={{paddingTop:"24px"}}>
                  <p className='dash-para'>Knit your business idea into a growth story, strap it with<br />
                    the latest technology and Shopdiy would help you get<br />
                    that competitive edge</p>
                </div>
                <div className="d-flex gap-2" style={{paddingBottom:"24px" }}>
                <button className='btn-primary'>Redefining E commerce</button>
                  <button className='btn-white'>Learn More</button>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <div className="card-para" style={{color:"#716F7A"}}>Available on</div>
                  <img src={require("../Assets/google-play.png")} alt="google=paly"/>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className="d-flex justify-content-center mk-1">
              <img src={require("../Assets/banner.webp")} alt="banner" className="hero-image" style={{width:"100%"}}/>
            </div>
          </div>
        </div>
      </section>
    )
}
export {Banner}