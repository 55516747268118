import React from 'react';
import "./index.scss"
function About() {
  return (
    <section className='container'>
    <div className="about">
      <h2>About Page</h2>
      <p>This is the About page of my app.</p>
    </div>
    </section>
  );
}

export { About };
