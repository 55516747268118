import React, { useState } from "react";
import { BsFilterLeft } from "react-icons/bs";
import { NavLink } from 'react-router-dom';
import { NavBarModal } from "./NavBarModal";
import { RxCross2 } from "react-icons/rx";
const Header = () => {
  const [togle, settogole] = useState(false)
  return (
    <header className='header'>
      <div className='container'>
        <div className='d-flex justify-content-between'>
          <div className="d-flex align-items-center gap-3">
            {!togle?<div className="togle" onClick={() => settogole(!togle)}>
              <BsFilterLeft size={24} color="black" />
            </div>:
            <div className="togle" onClick={() => settogole(!togle)}>
              <RxCross2 size={24} color="black" />
            </div>}
            <div className='logo'>
              <NavLink to="/">
                <img src={require("../Assets/logo.webp")} alt='logo' style={{ width: "100%", height: "100%" }} />
              </NavLink>
            </div>
          </div>
          <nav>
            <ul className="m-0" style={{ display: "flex", gap: "40px" }}>
              <li className="nav-item">
                <NavLink exact to="/" activeClassName="active">Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about" activeClassName="active">About</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/features" activeClassName="active">Features</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/blog" activeClassName="active">Blog</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" activeClassName="active">Contact</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/purchase" activeClassName="active">Purchase Now</NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <NavBarModal isOpen={togle}  settogole={settogole} />
    </header>
  )
}
export { Header }