import React from "react";

const EmbracingCloud = () => {
    return (
        <section className='' style={{ background: "#BAD8D5" }}>
            <div className='container'>
                <div className='row' style={{ paddingTop: "42px" }}>
                    <div className='col-md-6'>
                        <div className="d-flex justify-content-center">
                            <img src={require("../Assets/image-clound.webp")} className="img-width" alt='image-clound' style={{maxWidth:"444px"}} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div>
                            <h4 className='dash-ser'>Embracing The Cloud</h4>
                        </div>
                        <div>
                            <p className="font-p">By harnessing the power of cloud we revolutionize your business operations and help you thrive in the digital age</p>
                        </div>
                        <div className="p-0">
                            <div className="f-1"><div className="f-2">1</div><div className="f-3">Scalability</div></div>
                            <div className="f-1"><div className="f-2">2</div><div className="f-3">Access Control</div></div>
                            <div className="f-1"><div className="f-2">3</div><div className="f-3">Compliance</div></div>
                            <div className="f-1"><div className="f-2">4</div><div className="f-3">Security</div></div>
                            <div className="f-1"><div className="f-2">5</div><div className="f-3">Flexibility</div></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export { EmbracingCloud }